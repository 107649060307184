/* eslint-disable react/no-unescaped-entities */
import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../../components/layout'
import SEO from '../../components/seo'
import '../../less/cms.less'

const TermsAndConditions = () => {
  return (
    <Layout>
      <SEO title="Terms" />
      <div className="cms-container">
        <h1 className="cms-container__title">Terms of Use</h1>
        <div className="cms-container__wrapper cms-container__wrapper__terms">
          <p>
            <strong>Last updated: May 15, 2023.</strong>
          </p>

          <p>
            Welcome to MealPal! These Terms of Use (“Terms”) are a contract between you and MealPal Ltd (“MealPal” or
            “we”) and govern your access to and use of the MealPal UK website located at{' '}
            <a href={`${process.env.GATSBY_LANDING_PAGE_URL}/uk/`}>{`${process.env.GATSBY_LANDING_PAGE_URL}/uk/`}</a>,
            plus MealPal's mobile application (such as for iPhone or Android) and any content, and products and/or
            services made available to you through MealPal (collectively, the “Site”).
          </p>
          <p>
            These Terms, together with our policy governing the processing and use of your personal data (our "Privacy
            Policy") and any applicable terms and conditions related to any promotional offers provided to you for use
            with your MealPal membership or MealPal subscription, constitute the entire agreement between you and us
            related to your MealPal subscription or membership ("the Agreement").
          </p>
          <p>
            <strong>A MealPal Subscription</strong> is when you buy a set of meals and have a period of time to use
            them. With a subscription, you have already paid for the cost of all of the meals and do not need to make
            any additional payments when reserving.
          </p>
          <p>
            <strong>A MealPal Membership</strong> is when you pay an access fee to buy individual meals from the MealPal
            platform at variable prices, as indicated on the platform. The MealPal Membership Fee is £4.99 per month and
            does not cover the cost of any meals, but covers the cost of access to the platform. You may receive your
            first £4.99 membership fee for Free, but, will be charged for subsequent cycles as indicated below.
          </p>
          <p>
            Please read these Terms carefully before accessing and using the Site. Our relationship is subject to these
            Terms and they impose certain responsibilities upon you and they exclude and limit our liability to you in
            the event of loss or damage (see clause 15 and 18).
          </p>
          <p>
            You expressly accept that we will start providing you this service within your 14-day cancellation period.
          </p>
          <ol>
            <li>
              INFORMATION ABOUT US AND HOW TO CONTACT US
              <ol type="a">
                <li>
                  Who we are. We are MealPal Ltd, whose registered company number is 10542788. Our registered office is
                  Ashford House, Grenadier Road, Exeter, EX1 3LH
                </li>
                <li>
                  How to contact us. You can contact us by writing to us via email to{' '}
                  <a href="mailto:hello@mealpal.co.uk">hello@mealpal.co.uk</a>.
                </li>
                <li>
                  How we may contact you. If we have to contact you we will do so by email (using the email you provided
                  on registration)
                </li>
              </ol>
            </li>
            <li>
              TERMS OF USE
              <ol type="a">
                <li>
                  Acceptance of Terms. By accessing and/or using the Site, you accept and agree to be bound by these
                  Terms, just as if you had agreed to these Terms in writing. If you do not agree to these Terms, do not
                  use the Site.
                </li>
                <li>
                  Amendment of Terms. MealPal may amend these Terms from time to time. Unless we provide a delayed
                  effective date, all amendments will be effective upon posting of such updated Terms. Your continued
                  access to or use of the Site after such period constitutes your consent to be bound by the Terms, as
                  amended.
                </li>
                <li>
                  Additional Terms. In addition to these Terms, when using particular plans, offers, products, services
                  or features, you will also be subject to any additional posted guidelines, or rules applicable to such
                  plan, offer, product, service or feature, which may be posted and modified from time to time. All such
                  additional terms are hereby incorporated by reference into the Terms, provided that in the event of
                  any conflict between such additional terms and the Terms, the Terms shall control.
                </li>
              </ol>
            </li>
            <li>
              YOUR CANCELLATION RIGHTS AS A CONSUMER
              <ol type="a">
                <li>
                  You have a cooling off period, this is a legal right to cancel your MealPal Agreement by no later than
                  the end of 14 calendar days from the day the MealPal Agreement was entered into. This means that if
                  you change your mind or for any other reason you decide that you do not want us to provide the
                  Services to you, you can simply notify us of your decision to cancel the Agreement.
                </li>
                <li>
                  You do not have to give us a reason for exercising your right to cancel during the 14 calendar day
                  period referred to in clause 3a.
                </li>
                <li>
                  To cancel the Agreement under clause 3a, you just need to let us know that you have decided to cancel
                  within the 14 day calendar period referred to in clause 3.1. The easiest way for to do this is to
                  cancel your Subscription Agreement from the ‘My Account’ page using the Site, and the Membership
                  Agreement by emailing <a href="mailto:hello@mealpal.co.uk">hello@mealpal.co.uk</a>.
                </li>
                <li>
                  You expressly accept that we will start to provide the Services before the end of the 14 calendar day
                  period and that you will lose the right to cancel if the contract has been fully performed within the
                  14 day period.
                </li>
                <li>
                  Pursuant to clause 3d, if we have started to provide the Services (or any part of them) and you
                  exercise your right to cancel pursuant to clause 3a then we will charge you an amount in proportion to
                  the Services you have received up to and including the time you informed us of your decision to cancel
                  the Services, in comparison with the full coverage of the Agreement. We will tell you what these
                  charges are when you contact us.
                </li>
              </ol>
            </li>
            <li>
              MEALPAL PLATFORM
              <ol type="a">
                <li>
                  MealPal Platform. MealPal is a platform that enables consumers to reserve and pick up meals according
                  to their chosen plan (details set out at clause 4c) from the restaurants, food service providers, and
                  chefs that sign up with MealPal (“Merchants”) (the "Services"). Through the MealPal Site you can
                  access third party products and services.
                </li>
                <li>
                  MealPal is not a restaurant, chef or food service provider and does not own, operate or control any of
                  the restaurants, chefs, or food services facilities accessible through the Site.
                </li>
                <li>
                  Plans. MealPal offers a variety of Plans. Not all plans are available in all markets. Some of our most
                  popular plans are listed below.
                  <ol type="1">
                    <li>
                      30-Day Plans. MealPal offers 30-day subscription plans (“30-Day Plans”). You may purchase a 30-Day
                      Plan for up to 12 meals per 30-day period (“12 Meal 30-Day Plan”) or up to 20 meals per 30-day
                      period (“20 Meal 30-Day Plan”). 30-Day Plans renew automatically upon the use of all of the meals
                      or at the end of the 30 day cycle, whichever comes first. Unused meals do not roll over to the
                      next cycle.
                    </li>
                    <li>
                      Flex Plans. MealPal also offers subscription plans which permit you to purchase a fixed number of
                      meals for a fixed price that can be used for a more flexible time period (“Flex Plans”). Flex Plan
                      meals expire one year from date of purchase. Flex Plans renew automatically upon the use of all of
                      the meals or at the end of the 365 day cycle, whichever comes first. Unused meals do not roll over
                      to the next cycle.
                    </li>
                    <li>
                      Rollover Plans. MealPal also offers subscription plans which permit you to purchase a set number
                      of meals that can roll over for a subsequent cycle (“Rollover Plans”). Rollover Plans renew
                      automatically upon the use of all of the meals or upon the end of the 30 day cycle, whichever
                      comes first. At the end of the cycle, unused meals may be rolled over to the next cycle as long as
                      the next cycle is a Rollover plan for at least the same number of meals as the current rollover
                      plan. If a user renews to a rollover plan with a lower number of meals than their prior plan, then
                      the maximum number of unused meals that can rollover is equal to the maximum number of meals on
                      their new rollover plan. Unused meals can only roll over for one cycle.
                    </li>
                    <li>
                      Membership Plans. MealPal also offers a membership plan which permits you pay an access fee that
                      unlocks the ability to buy individual meals from the MealPal platform at variable prices, as
                      indicated on the platform. Membership Plans renew automatically at the end of the 30 day cycle.
                      Membership Plans will automatically renew every 30 days and you will be charged the membership fee
                      again on which the Membership Cycle renews.
                    </li>
                  </ol>
                </li>
                <li>
                  The MealPal membership plans and subscription plans are offered under various options. The MealPal
                  membership plans and subscription plans are offered under various options. You will select which plan
                  you are on when you sign up. If you would like to change your plan to a different plan, you may
                  request a change by emailing <a href="mailto:hello@mealpal.co.uk">hello@mealpal.co.uk</a> with “Change
                  Membership” in subject line; or ii) accessing “My Account” from the MealPal Menu dropdown. To change
                  your Membership Plan in My Account, click “Change Plan for Next Cycle” and then select from one of the
                  available options.
                </li>
                <li>
                  Requests to change your membership plan or subscription plan must be received at least 7 days prior to
                  your next billing date (i.e., if your next billing date is 30th September, notify MealPal that you
                  want to change your membership plan or subscription plan by 23rd September).
                </li>
                <li>
                  Plans can only be changed once per billing cycle. If you change your plan from a full membership or
                  full subscription, there is no fee to return to a full membership or full subscription.
                </li>
                <li>
                  We may offer a number of membership plans and subscription plans, including special promotional plans
                  or memberships, with differing conditions and limitations. We reserve the right to modify, terminate
                  or otherwise amend our offered membership plans in accordance with clause 2b.
                </li>
                <li>
                  Cycles. Both MealPal Memberships and MealPal Subscriptions have associated cycles. Your cycle starts
                  on the date that you sign up for a subscription or membership and submit payment via a valid Payment
                  Method. Unless we otherwise communicate a different time period to you at the time of sign up (such as
                  a multi-cycle commitment plan), each MealPal cycle is 30 days in length (a “Cycle”), except for the
                  Flex Plan (defined in section 3(c) above), which has a MealPal cycle of 365 days in length (a
                  “Cycle”).
                </li>
                <li>
                  At the end of the first and any following subscription or membership period, your subscription and/or
                  membership will renew automatically for another equivalent period, unless you notify us before renewal
                  that you want to cancel. You understand your subscription and/or membership will automatically renew
                  and you authorise us (without further notice to you) to collect the then-applicable periodic fee using
                  any payment method we have on record for you. For example, if you purchase your MealPal on 5 April,
                  your cycle will automatically renew 30 days later, on 5 May (as further explained in “Billing Cycles,”
                  below). You must provide us with a current, valid, accepted method of payment (which we may update
                  from time to time, “Payment Method”) to use MealPal. We will automatically bill the fee to your
                  Payment Method for each cycle until your subscription and/or membership is cancelled or terminated.
                </li>
                <li>
                  Eligible Meals. Each subscription member is entitled to reserve no more than 1 meal per weekday
                  (excluding Public Holidays in the UK) from 1 participating Merchant. Each membership member can
                  reserve up to 8 meals per weekday (excluding Public Holidays in the UK) from 1 participating Merchant.
                  The member may use a meal at the Merchant of their choice available to such member on our platform.
                  MealPal reserves the right to change from time to time the number of Eligible Meals a member can
                  reserve per cycle, membership plan, subscription plan, geography, Merchant or otherwise in accordance
                  with clause 2b.
                </li>
                <li>
                  Merchant and Meal Availability and Allocation. MealPal makes no guarantee on the availability of
                  particular Merchants, meals, menu choices or other inventory, and availability may change over time,
                  including during the course of any given Subscription or Membership Cycle.
                </li>
                <li>
                  Meal Modifications. MealPal Merchants are unable to make any modifications to the meals that they are
                  offering on the platform. All meals will be prepared as described. Modifications, substitutions and
                  any other changes or requests for changes are strictly forbidden and may result in a termination of
                  your MealPal subscription or membership.
                </li>
                <li>
                  Use of MealPal. Your MealPal subscription and/or membership is personal to you and you agree not to
                  create more than one account. Members cannot transfer or gift meals to third parties, including other
                  MealPal members. MealPal may not be used for commercial purposes. To use your MealPal subscription
                  and/or membership you must have access to the Internet. We continually update and test various aspects
                  of the MealPal platform. We reserve the right to, and by using our service you agree that we may,
                  include you in or exclude you from these tests without notice.
                </li>
                <li>
                  In order to pick up your meal, you must have a mobile device that supports the latest version of the
                  MealPal Android App or iOS App. The MealPal Android App or iOS App must be installed on your mobile
                  device, and you must have a working camera. To pick up your reserved meal, you will be required to
                  open the app, log in to your account, and scan the QR code found at the restaurant. Following the
                  scan, you must then show the confirmation success screen to the merchant. MealPal reserves the right
                  to modify this pickup process as required by the restaurant and/or MealPal.
                </li>
                <li>
                  In order to use certain features on the MealPal platform, you are required to have a mobile device.
                  MealPal does not warrant that the Platform will be compatible or interoperable with your mobile
                  device. The Platform requires and uses data access and text messaging capabilities. Carrier rates for
                  data and text messaging may apply, and you are responsible for all of these charges.
                </li>
              </ol>
            </li>
            <li>
              BILLING
              <ol type="a">
                <li>
                  Recurring Billing. By entering the MealPal Subscription or Membership Agreement, you authorise us to
                  charge you for your initial subscription or membership period and a recurring fee every 30 days at the
                  then current rate, which may change from time to time ("Billing Cycle"). You acknowledge (subject to
                  clause 2.2) that the amount billed each cycle may vary for reasons that may include differing amounts
                  due to promotional offers and/or changing or adding a plan, and you authorise us to charge your
                  Payment Method for such varying amounts, which may be billed once every 30 days in one or more
                  charges. You also authorise us to charge you any other fees you may incur in connection with your use
                  of the Site, such as any applicable sign-up fee or taxes, as further explained below.
                </li>
                <li>
                  Billing Cycle. When you sign up and purchase your MealPal subscription or membership, your first cycle
                  will be billed immediately. Your subscription and/or membership will automatically renew every 30 days
                  and you will be charged again. We reserve the right to change the timing of our billing (and if we do,
                  we’ll make adjustments to the amounts we charge, as appropriate). Your renewal date may change due to
                  changes in your subscription or membership.
                </li>
                <li>
                  Billing Holds. You may put your Membership Plan on hold (“Billing Hold”) at any time for up to ninety
                  (90) days. For a Billing Hold to be effective for your next Membership Cycle, it must be received at
                  least 7 days prior to the end of your then-current membership Cycle or before you reserve the last
                  meal in your cycle, whichever comes first. To establish a Billing Hold, i) email{' '}
                  <a href="mailto:hello@mealpal.co.uk">hello@mealpal.co.uk</a> with “Billing Hold” in the subject line;
                  or ii) access “My Account” from the MealPal Menu dropdown. To establish a Billing Hold in My Account,
                  click “Change Plan for Next Cycle”; then “Hold or Cancel My Account”; and then “Hold Account”. While
                  you are on a Billing Hold, you will not be charged, and your Membership Plan will automatically begin
                  again with a new Membership Cycle commencing on the day that you end your Billing Hold.If you go on
                  hold, and are on one of our rollover plans, any unused meals before you go on hold will not rollover
                  to your cycle when you return from hold.
                </li>
                <li>
                  Refunds - Subscription. We will provide a refund to members for their current Billing Cycle in the
                  following circumstances:
                  <ol type="i">
                    <li>
                      if you are cancelling your subscription outside of the 14 day cancellation period and request a
                      refund within 5 days of your initial purchase; or
                    </li>
                    <li>
                      if your subscription is cancelled prior to the end of a period, for which you have incurred a
                      charge, due to disability or death. In each case we reserve the right to charge a fee to cover the
                      cost of any meals or other services you may have used prior to your cancellation as set out in
                      clause 5c(ii).
                    </li>
                    <li>
                      The current rate that will be charged is £8 per meal for any meals reserved in London. (Such meal
                      fee charges not to exceed the cost of the subscription itself). Any additional discounts or
                      refunds that we may choose to provide are in our sole discretion and do not entitle you to any
                      future discounts or refunds in the future for similar instances.
                    </li>
                  </ol>
                </li>
                <li>
                  Price Changes. We reserve the right to adjust pricing at any time in accordance with clause 2b. Unless
                  we expressly communicate otherwise, any price changes to your subscription or membership will take
                  effect on your next billing cycle upon notice communicated through a posting on the MealPal website or
                  mobile applicable or such other means as we may deem appropriate from time to time, such as email.
                </li>
                <li>
                  Payment Methods. You may edit your Payment Method information by using the Site for Subscription
                  members, using the iOS app for Membership members, or by emailing{' '}
                  <a href="mailto:hello@mealpal.co.uk">hello@mealpal.co.uk</a>. If a payment is not successfully
                  settled, due to expiration, insufficient funds or otherwise, and you do not contact us with your
                  Payment Method information or cancel your account (see, “Termination” below), you nonetheless will
                  remain responsible for any uncollected amounts and authorise us to continue billing the Payment
                  Method, as it may be updated, including in the event you attempt to create a new account. This may
                  result in a change to your payment billing dates. If we cannot charge your account, we reserve the
                  right, but are not obligated, to terminate your access to our Site or any portion thereof.
                </li>
                <li>
                  Reservation and Cancellation of Meals. You must reserve and cancel your MealPal meals only through the
                  MealPal Site. It is a breach of your MealPal Agreement if you cancel, change, or request a meal
                  directly with a Merchant. Meals can be reserved starting at 5:00pm local time on the evening before
                  the meal will be received. All meals must be reserved by 1:30pm local time on the day when the meal
                  will be received. If a meal is not reserved by 1:30pm local time on the day the meal would have been
                  received, the customer will not be eligible to receive a meal on that day. There are no refunds for
                  meals not received or not ordered in time. There is no value tied to each individual meal and meals
                  not used do not rollover to future billing cycles. If you cancel a meal before 10:30am local time on
                  the day the meal would have been received, you will have the opportunity to reserve another meal
                  before 1:30pm. If you cancel a meal after 10:30am local time on the day the meal would have been
                  received, that meal will count toward your total meals for your current billing cycle.
                </li>
                <li>
                  Trials. From time to time we may offer a trial subscription or membership that includes standard
                  access to the MealPal platform during a trial period. Unless otherwise communicated, a trial begins at
                  the moment of sign up and ends at 11:59pm GMT on the last day of the trial (for a one-week trial, this
                  would be the same weekday of the following week). Subject to Clause 3, each trial subscription or
                  membership automatically will convert to a regular 30 day membership and price unless cancelled by
                  12pm GMT on the day before the last day of trial. Customers that cancel and do not convert to a
                  regular subscription or membership may not reserve meals taking place after the end of the trial
                  subscription or membership period (even if booking occurred before the end of the applicable trial
                  period). Unless we expressly communicate otherwise, trial subscriptions or memberships are only
                  available to new customers that have never had a MealPal account before, are not transferable, may not
                  be combined with other offers or redeemed for cash and are void where prohibited.
                </li>
                <li>
                  <p>
                    Gifts and Promotions. From time to time we may make available gift cards for MealPal subscription or
                    membership, other types of promotions or promotional plans (including through the use of promotional
                    codes or those provided as part of a third party promotion). Gift cards, promotions and promotional
                    plans may only be redeemed as described, and may be subject to additional or different terms. Unless
                    otherwise expressly communicated to you in connection with your redemption, gift cards, promotions
                    and promotional plans are (i) only available to new customers that have never had a MealPal account
                    before, and (ii) may only be redeemed once, upon initial sign-up for your first MealPal membership.
                    MealPal has the right to charge your Payment Method the full retail value of any gift card,
                    promotion, or promotional plan that is redeemed by you more than once, or otherwise than as
                    described in the specifics of the gift card, promotion, or promotional plan. For example, if you
                    redeem a promotion offering £30 off a new MealPal Membership Plan at any time after your initial
                    signup for your first MealPal membership, MealPal may charge you £30 in addition to any fees and
                    charges applicable to your membership. Gift cards, promotions, and promotional plans are not
                    transferable, cannot be redeemed for cash, and may not be combined with other offers and are void
                    where prohibited. To be eligible for certain promotions, you must enroll in a 20 Meal 30-Day Plan.
                    In order to receive a promotion, you may be required to provide and verify your cell phone number.
                    The cell phone number you verify must be a unique cell phone number that is not associated with
                    another account in order to receive the promotion. We may also review any other aspects of your
                    account to determine if it is a new customer account or not, and the determination is at MealPal’s
                    discretion.
                  </p>
                  <p>
                    From time to time we may also make available promotions for referring others to the MealPal service.
                    All referrals require successful validation by MealPal to confirm eligibility. Confirmation of
                    eligibility may include (but is not limited to) validation of email, phone number, credit card,
                    name, device ID, and other customer attributes. If a referral does not reserve a meal within the
                    first 30 days of their membership, they will not be counted as a referral. Final eligibility and
                    qualification for all referrals is at the sole discretion of MealPal.
                  </p>
                  <p>
                    If you have created multiple accounts using different email addresses and/or associated the same
                    phone number with multiple email accounts, it is possible that you will receive a marketing
                    promotion that you are not eligible for. As noted in section 2N, it is a violation of these terms to
                    create multiple MealPal accounts.
                  </p>
                  <p>Final eligibility for all marketing promotions is at the sole discretion of MealPal.</p>
                </li>
                <li>VAT clause. Any applicable VAT and fees are inclusive in all MealPal plans.</li>
                <li>
                  Holidays. We observe the following holidays each year: Good Friday, Easter Monday, Early May bank
                  holiday, Spring bank holiday, Summer bank holiday, Christmas Day, Boxing Day. On holidays, you will
                  not be able to reserve meals on the MealPal platform. Occasionally, multiple holidays will be included
                  in a given 30-day billing cycle. During these cycles it is possible that the maximum number of meals
                  you can reserve on your plan will be less than the number you are entitled to. The total price for the
                  plan during these cycles does not change. You will not receive a refund for any of your meals. You
                  will not be able to pick up multiple meals on any day.
                </li>
                <li>
                  Your Home City. The rate for meals and fees may vary by location. Your "Home City" will be determined
                  based on the market where you have most of your meals during any given cycles. If you are charged at
                  the beginning of your cycle for a Home City that has a lower rate than what ends up being your actual
                  Home City during that cycle, then you will be charged the difference at the end of that cycle. There
                  are no refunds for using MealPal in a less expensive Home City.
                </li>
              </ol>
            </li>
            <li>
              OTHER FEES
              <ol type="a">
                <li>
                  <p>
                    Fees We Charge. Your MealPal subscription fee covers your access to eligible meals as explained
                    above. We reserve the right to change the policy in accordance with clause 2b regarding when we
                    charge fees, to introduce additional fees (including but not limited to, a sign-up fee, late
                    cancellation fee, or missed meal pick up fee) and to change the amount of any such fees at any time.
                    Additionally, from time to time we may allow you to purchase additional meals, products or services
                    through the MealPal Site. If you choose to purchase any of these offerings, you will be responsible
                    to pay the applicable fees in addition to your subscription fee.
                  </p>
                  <p>
                    Your MealPal membership fee provides access to purchase eligible meals as explained above. You will
                    be charged the MealPal membership fee with the first purchase of a meal in a given Membership Cycle.
                  </p>
                </li>
                <li>
                  Third Party Fees for Using MealPal. You are also responsible for all third party charges and fees
                  associated with connecting and using the Site, including but not limited to fees such as internet
                  service provider fees, telephone and computer equipment charges, and any other fees necessary to
                  access the Site.
                </li>
              </ol>
            </li>
            <li>
              TERMINATION
              <ol type="a">
                <li>
                  We may end your Membership Agreement if you break it or if we have a legal right to do so. We may end
                  the Agreement at any time for convenience and without giving reasons by giving 7 days' notice. If we
                  end your Subscription or Membership for our convenience we will refund you the unused prepaid part of
                  your current billing cycle prorated to the number of days left in that billing cycle after we cancel
                  your Membership Agreement.
                </li>
                <li>
                  Upon any termination, we may immediately deactivate your account and all related information and/or
                  bar any further access to your account information and the Site. in accordance with clause 5c.
                </li>
                <li>
                  You must compensate us if you break the Membership Agreement. If we end the Membership Agreement as
                  set out in clause 7a we will refund any money you have paid in advance for any Services we have not
                  provided under the Membership Agreement, save that we reserve the right to retain any monies you have
                  paid to us and/or seek to recover from you reasonable compensation for the net costs and/or or loss of
                  profits we will incur as a direct result of you breaking the Agreement.
                </li>
                <li>
                  <p>
                    Your right to end the Agreement. Once you are outside of the 14 day "cooling off" period in clause
                    3a you may terminate your subscription at any time with 7 days’ notice by emailing{' '}
                    <a href="mailto:hello@mealpal.co.uk">hello@mealpal.co.uk</a>. Following any cancellation you will
                    continue to have access to your subscription through the end of your current prepaid billing period.
                    You can also terminate the Membership Agreement because you have a legal right to cancel or if you
                    have a good reason to do so (i.e. only for a reason set out at 7d (i) to 7d (ii) below), in which
                    case the Membership Agreement will end immediately and, if you have made any payment in advance we
                    will refund you in accordance with clause 5.3. The relevant reasons are:
                  </p>
                  <ol type="i">
                    <li>
                      we have told you about an upcoming change to the Services or these Terms which you do not agree to
                      (as per clause 2b);
                    </li>
                    <li>you have a legal right to end the Agreement because of something we have done wrong.</li>
                  </ol>
                </li>
                <li>
                  Infringing or Fraudulent Activity. MealPal does not permit copyright infringing activities and
                  reserves the right to terminate access to the Site and remove all content submitted by any persons who
                  are found to be infringers. Any suspected fraudulent, abusive, or illegal activity that may be grounds
                  for termination of your use of the Site may be referred to appropriate law enforcement authorities.
                  These remedies are in addition to any other remedies MealPal may have at law or in equity.
                </li>
              </ol>
            </li>
            <li>
              ELIGIBILITY; REGISTRATION INFORMATION AND PASSWORD; SITE ACCESS
              <ol type="a">
                <li>
                  Eligibility Criteria. The availability of all or part of our Site may be limited based on demographic,
                  geographic, or other criteria as we may establish from time to time. You understand and agree we may
                  disallow you from subscribing to MealPal or may terminate your subscription at any time based on these
                  criteria. For example, you must be 18 years of age or older to use this Site or to purchase a MealPal
                  subscription or membership.
                </li>
                <li>
                  THESE TERMS ARE ONLY APPLICABLE TO USERS OF THE SITE LOCATED IN THE UNITED KINGDOM AND SEPARATE TERMS
                  APPLY TO USERS IN OTHER COUNTRIES. THE SITE IS NOT AVAILABLE TO ANY USERS SUSPENDED OR REMOVED FROM
                  THE SITE BY MEALPAL. BY USING THE SITE, YOU REPRESENT THAT YOU ARE A PERMANENT RESIDENT OF THE UNITED
                  KINGDOM WHO HAS NOT BEEN PREVIOUSLY SUSPENDED OR REMOVED FROM THE SITE OR ANY OTHER MEALPAL SITE.
                  THOSE WHO CHOOSE TO ACCESS THE SITE DO SO AT THEIR OWN INITIATIVE AND ARE RESPONSIBLE FOR COMPLIANCE
                  WITH ALL LOCAL RULES INCLUDING, WITHOUT LIMITATION, RULES ABOUT THE INTERNET, DATA, EMAIL OR OTHER
                  ELECTRONIC MESSAGES, OR PRIVACY
                </li>
                <li>
                  Account registration requires you to submit to MealPal certain personal information, such as your
                  name, address, and mobile phone number. You are permitted to create only one account. You agree that
                  the information you provide to MealPal at registration and at all other times will be true, accurate,
                  current, and complete. You also agree that you will ensure that this information is kept accurate and
                  up-to-date at all times. When you register, you will be asked to create a password. You are solely
                  responsible for maintaining the confidentiality of your account and password and for restricting
                  access to your computer, and you agree to accept responsibility for all activities that occur under
                  your account.
                </li>
              </ol>
            </li>
            <li>
              PRIVACY
              <p>
                Your privacy is important to MealPal. The MealPal{' '}
                <a href={`${process.env.GATSBY_LANDING_PAGE_URL}/uk/privacy-policy/`}>Privacy Policy</a> is hereby
                incorporated into these Terms by reference. Please read the privacy policy carefully for information
                relating to MealPal’ collection, use, and disclosure of your personal information. When you make a
                reservation, the applicable Merchant partner will have access to certain information about you, such as
                your name and email address, so it can provide services to you, communicate with you regarding the meal
                you reserved and send you other communication that may be of interest to you such as marketing offers.
                Please see the Privacy Policy for more information.
              </p>
            </li>
            <li>
              PROHIBITED CONDUCT
              <p>You will not:</p>
              <ol type="a">
                <li>Cancel any MealPal meals directly with a Merchant, rather than through the MealPal Site;</li>
                <li>Harass, threaten, or defraud users, members or staff of MealPal or Merchants;</li>
                <li>Make unsolicited offers, advertisements, proposals, or send junk mail or “spam” to users;</li>
                <li>Impersonate another person or access another user’s account without that person’s permission;</li>
                <li>Share MealPal passwords with any third party or encourage any other user to do so;</li>
                <li>
                  Permit third parties to use any meals reserved under your own membership, including other members;
                </li>
                <li>
                  Misrepresent the source, identity, or content of information transmitted via the Site, including
                  deleting the copyright or other proprietary rights;
                </li>
                <li>
                  Upload material (e.g. virus) that is damaging to computer systems or data of MealPal or users of the
                  Site;
                </li>
                <li>
                  Upload copyrighted material that is not your own or that you do not have the legal right to
                  distribute, display, and otherwise make available to others; or
                </li>
                <li>
                  Upload or send to Site users pornographic, threatening, embarrassing, hateful, racially or ethnically
                  insulting, libellous, or otherwise inappropriate content.
                </li>
              </ol>
            </li>
            <li>
              PROHIBITED USES
              <p>
                As a condition of your use of the Site, you will not use the Site for any purpose that is unlawful or
                prohibited by these Terms. You may not use the Site in any manner that, in our sole discretion, could
                damage, disable, overburden, or impair it or interfere with any other party’s use and enjoyment of the
                Site. You may not attempt to gain unauthorised access to the Site, or any part of the Site, other
                accounts, computer systems or networks connected to the Site, or any part of them, through hacking,
                password mining, or any other means or interfere or attempt to interfere with the proper working of the
                Site or any activities conducted on the Site. You may not remove, circumvent, disable, damage or
                otherwise interfere with security-related features of the Site, any features that prevent or restrict
                use or copying of any content accessible through the Site, or any features that enforce limitations on
                the use of the Site or the content therein. You may not obtain or attempt to obtain any materials or
                information through any means not intentionally made available through the Site. You agree neither to
                modify the Site in any manner or form, nor to use modified versions of the Site, including (without
                limitation) for the purpose of obtaining unauthorised access to the Site. The Site may contain robot
                exclusion headers. You agree that you will not use any robot, spider, scraper, or other automated means
                to access the Site for any purpose without our express written permission or bypass our robot exclusion
                headers or other measures we may use to prevent or restrict access to the Site.
              </p>
              <p>
                MealPal reserves the right to refuse service, terminate accounts, remove or edit content, or cancel
                orders in its sole discretion.
              </p>
            </li>
            <li>
              USER SUBMISSIONS OF REVIEWS
              <ol type="a">
                <li>
                  General. The Site provides certain features which enable you and other users to submit, post, and
                  share reviews. These submissions may include without limitation text, graphic and pictorial works, or
                  any other content submitted by you and other users through the Site (“User Submissions”). User
                  Submissions are displayed for informational purposes only and are not controlled by MealPal. MealPal
                  cannot guarantee any anonymity or confidentiality with respect to any User Submissions, and strongly
                  recommends that you think carefully about what you upload to the Site. You understand that all User
                  Submissions are the sole responsibility of the person from whom such User Submission originated. This
                  means that you, and not MealPal, are entirely responsible for all User Submissions that you upload,
                  post, email, transmit, or otherwise make available through the Site.
                </li>
                <li>
                  Right to Remove or Edit User Submissions. MealPal makes no representations that it will publish or
                  make available on the Site any User Submissions, and reserves the right, in its sole discretion, to
                  refuse to allow any User Submissions on the Site, or to edit or remove any User Submission at any time
                  with or without notice.
                </li>
                <li>
                  Licence Grant by You to MealPal. You retain all your ownership rights in original aspects of your User
                  Submissions. By submitting User Submissions to MealPal, you hereby grant MealPal and its affiliates,
                  sublicensees, partners, designees, and assignees of the Site (collectively, the “MealPal Licensees”) a
                  worldwide, nonexclusive, fully paid-up, royalty-free, perpetual, irrevocable, sublicensable, and
                  transferable license to use, reproduce (including by making mechanical reproductions), distribute,
                  modify, adapt, translate, prepare derivative works of, publicly display, publish, publicly perform,
                  and otherwise exploit your User Submissions and derivatives thereof in connection with the Site and
                  MealPal’s (and its successors’) business, including, without limitation, for marketing, promoting, and
                  redistributing part or all of the Site (and derivative works thereof), in any media formats and
                  through any media channels now known or hereafter discovered or developed.
                </li>
                <li>
                  User Submissions Representations and Warranties. You are solely responsible for your own User
                  Submissions and the consequences of posting or publishing them. In connection with User Submissions,
                  you affirm, represent, and warrant that: (i) you own, or have the necessary licenses, rights,
                  consents, and permissions to use and authorise MealPal to use all patent, trademark, copyright, or
                  other proprietary rights in and to your User Submissions to enable inclusion and use of your User
                  Submissions in the manner contemplated by MealPal and these Terms, and to grant the rights and license
                  set forth above, and (ii) your User Submissions, MealPal’s or any MealPal Licensee’s use of such User
                  Submissions pursuant to these Terms, and MealPal’s or any of MealPal Licensee’s exercise of the
                  license rights set forth above, do not and will not: (a) infringe, violate, or misappropriate any
                  third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy
                  right, right of publicity, or any other intellectual property or proprietary right; (b) cause injury
                  to any other person; (c) violate these Terms or any applicable law or regulation; or (d) require
                  obtaining a license from or paying fees or royalties to any third party for the exercise of any rights
                  granted in these Terms, including, by way of example and not limitation, the payment of any royalties
                  to any copyright owners, including any royalties to any agency, collection society, or other entity
                  that administers such rights on behalf of others. MealPal may, but is not obligated to, monitor and
                  edit or remove any activity or content, including but not limited to content that MealPal determines
                  in its sole discretion to be in violation of the standards of this Site. MealPal takes no
                  responsibility and assumes no liability for any User Submissions.
                </li>
                <li>
                  Inaccurate or Offensive User Submissions. You understand that when using the Site, you may be exposed
                  to User Submissions from a variety of sources and that MealPal does not endorse and is not responsible
                  for the accuracy, usefulness, safety, or intellectual property rights of or relating to such User
                  Submissions. You further understand and acknowledge that you may be exposed to User Submissions that
                  are inaccurate, offensive, indecent, or objectionable. YOU AGREE TO WAIVE, AND HEREBY DO WAIVE, ANY
                  LEGAL OR EQUITABLE RIGHTS OR REMEDIES YOU HAVE OR MAY HAVE AGAINST MEALPAL IN RELATION TO THE USER
                  SUBMISSIONS. thereto
                </li>
                <li>
                  Feedback. If you provide MealPal with any comments, bug reports, feedback, or modifications proposed
                  or suggested by you to the Site (“Feedback”), MealPal shall have the right to use such Feedback at its
                  discretion, including, but not limited to the incorporation of such suggested changes into the Site.
                  You hereby grant MealPal a perpetual, irrevocable, nonexclusive license under all rights necessary to
                  incorporate and use your Feedback for any purpose.
                </li>
              </ol>
            </li>
            <li>
              <p>OWNERSHIP; PROPRIETARY RIGHTS</p>
              <p>
                The MealPal website and mobile applications are owned and operated by MealPal. The visual interfaces,
                graphics, design, compilation, information, computer code, products, software (including any
                downloadable software), services, and all other elements of the Site provided by MealPal (“Materials”)
                are protected by the copyright, trade dress, and trademark laws of Canada, the United States, the United
                Kingdom and other countries, international conventions, and all other relevant intellectual property and
                proprietary rights, and applicable laws. Except for any content uploaded by you, all Materials contained
                on the Site are the copyrighted property of MealPal or its subsidiaries or affiliated companies and/or
                third-party licensors. All trademarks, service marks, and trade names are proprietary to MealPal or its
                affiliates and/or third-party licensors. Except as expressly authorised by MealPal, you agree not to
                sell, license, distribute, copy, modify, publicly perform or display, transmit, publish, edit, adapt,
                create derivative works from, or otherwise make unauthorised use of the Materials.
              </p>
            </li>
            <li>
              <p>THIRD-PARTY SITES, PRODUCTS AND SERVICES; LINKS</p>
              <p>
                The Site may include links or access to other web sites or services (“Linked Sites”) solely as a
                convenience to users. MealPal does not endorse any such Linked Sites or the information, material,
                products, or services contained on other linked sites or accessible through other Linked Sites.
                Furthermore, MealPal makes no express or implied warranties with regard to the information, material,
                products, or services that are contained on or accessible through linked sites. ACCESS AND USE OF LINKED
                SITES, INCLUDING THE INFORMATION, MATERIAL, PRODUCTS, AND SERVICES ON LINKED SITES OR AVAILABLE THROUGH
                LINKED SITES, IS SOLELY AT YOUR OWN RISK.
              </p>
              <p>
                Your correspondence or business dealings with, or participation in promotions of, advertisers found on
                or through the Site are solely between you and such advertiser. YOU AGREE THAT MEALPAL WILL NOT BE
                RESPONSIBLE OR LIABLE FOR ANY LOSS OR DAMAGE OF ANY SORT INCURRED AS THE RESULT OF ANY SUCH DEALINGS OR
                AS THE RESULT OF THE PRESENCE OF SUCH ADVERTISERS ON THE SITE.
              </p>
            </li>
            <li>
              <p>WARRANTIES AND DISCLAIMERS</p>
              <p>
                MEALS, AND OTHER NON-MEALPAL PRODUCTS AND SERVICES OFFERED VIA THE SITE ARE OFFERED AND PROVIDED BY
                THIRD PARTIES, NOT MEALPAL. YOUR COLLECTION AND USE OF THESE MEALS AND YOUR USE OF THESE NON-MEALPAL
                PRODUCTS AND SERVICES IS SOLELY AT YOUR OWN RISK.
              </p>
              <p>
                MealPal makes no guarantee of the ingredients contained in any meal. It is your responsibility to make
                sure that you can safely eat (or come into contact with) all of the ingredients contained in any meal.
              </p>
              <p>
                IN NO EVENT SHALL MEALPAL BE LIABLE FOR ANY ACT, ERROR OR OMISSION BY ANY THIRD PARTY, INCLUDING,
                WITHOUT LIMITATION, ANY WHICH ARISES OUT OF OR IS ANY WAY CONNECTED WITH A MEMBER’S VISIT TO A MERCHANT,
                A MEMBER’S USE OF OR CONSUMPTION OF A MEAL, SERVICE, PRODUCT OR APPOINTMENT MADE THROUGH THE SITE, OR
                THE PERFORMANCE OR NON-PERFORMANCE OF ANY THIRD PARTY PROVIDER IN CONNECTION WITH THE SERVICES.
              </p>
              <p>MEALPAL IS NOT AN AGENT OF ANY THIRD PARTY PROVIDER.</p>
              <p>
                THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION
                WITH OR THROUGH THE SITE ARE PROVIDED “AS IS” AND “AS AVAILABLE” WITHOUT WARRANTIES OF ANY KIND EITHER
                EXPRESS OR IMPLIED. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, MEALPAL, ON BEHALF OF
                ITSELF AND ITS SUPPLIERS AND PARTNERS, DISCLAIMS AND EXCLUDES ALL WARRANTIES, WHETHER STATUTORY, EXPRESS
                OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, AND NONINFRINGEMENT OF PROPRIETARY RIGHTS.
              </p>
              <p>
                WITHOUT LIMITING THE FOREGOING, MEALPAL DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS (I) THAT THE SITE
                AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR
                THROUGH THE SITE WILL BE UNINTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THE SITE
                AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR
                THROUGH THE SITE OR THE SERVER THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR OTHER HARMFUL
                COMPONENTS, OR (II) REGARDING THE USE OF THE SITE AND ANY DOWNLOADABLE SOFTWARE, CONTENT, SERVICES, OR
                APPLICATIONS MADE AVAILABLE IN CONJUNCTION WITH OR THROUGH THE SITE IN TERMS OF CORRECTNESS, ACCURACY,
                RELIABILITY, OR OTHERWISE. ANY MATERIAL OR DATA THAT YOU DOWNLOAD OR OTHERWISE OBTAIN THROUGH THE SITE
                IS AT YOUR OWN RISK. YOU ARE SOLELY RESPONSIBLE FOR ANY DAMAGES TO YOUR 12 COMPUTER SYSTEM OR LOSS OF
                DATA RESULTING FROM THE DOWNLOAD OF SUCH MATERIAL OR DATA.
              </p>
            </li>
            <li>
              <p>WAIVER AND RELEASE</p>
              <p>
                You understand that MealPal is not a restaurant or food provider or other service provider and the meals
                you consume are sourced and prepared by the applicable Merchant and not by MealPal. Although MealPal
                endeavours to offer inventory that is of high quality, MealPal is not responsible for the quality of any
                meals or service.
              </p>
              <p>
                Therefore, to the fullest extent permitted by law, and subject to clause 18c, you release and hold
                harmless MealPal, its parent, subsidiaries or affiliated entities, and each of their respective
                officers, directors, members, employees, consultants, contract employees, representatives and agents,
                and each of their respective successors and assigns, from any and all responsibility, claims, actions,
                suits, procedures, costs, expenses, damages and liabilities arising out of or in any way related to your
                participation in or use of your MealPal membership, including with respect to bodily injury, physical
                harm, illness, death or property damage.
              </p>
            </li>
            <li>
              <p>INDEMNITY</p>
              <p>
                You agree to indemnify and hold MealPal, its affiliated companies, and its suppliers and partners
                harmless from any claims, losses, damages, liabilities, including legal fees, arising out of your misuse
                of the Site, violation of these Terms, violation of the rights of any other person or entity, or any
                breach of the prior representations, warranties, and covenants.
              </p>
            </li>
            <li>
              OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU - YOUR ATTENTION IS DRAWN PARTICULARLY TO THIS
              CLAUSE
              <ol type="a">
                <li>
                  We are only responsible to you for foreseeable loss and damage caused by us. If we fail to comply with
                  these Terms, we are responsible for loss or damage you suffer that is a natural, foreseeable
                  consequence of our breaking this Membership Agreement or our failing to use reasonable care and skill,
                  but we are not responsible for any loss or damage that is not so foreseeable or for indirect or
                  consequential loss or damage. Loss or damage is foreseeable if either it is obvious that it will
                  happen or if, at the time the Membership Agreement was made, both we and you knew it might happen, for
                  example, if you discussed it with us during the sales process and we explicitly agreed with you in
                  writing that as part of your Membership Agreement that such loss or damage would be recoverable by
                  you.
                </li>
                <li>
                  MealPal’s liability to you is limited to £50 or the amounts, if any, paid by you to MealPal under this
                  agreement in the three months immediately prior to the event first giving rise to the claim, whichever
                  is more. The limitations in clauses 19.1 and 19.2 will apply to the maximum extent permitted by
                  applicable law, regardless of whether MealPal has been advised of the possibility of such damages and
                  regardless of whether any remedy fails of its essential purpose.
                </li>
                <li>
                  Responsibility we do not exclude: We do not in any way exclude or limit our liability for:
                  <ol type="i">
                    <li>
                      death or personal injury caused by our failure to use reasonable care and skill or that of our
                      employees, agents or sub-contractors;
                    </li>
                    <li>fraud or fraudulent misrepresentation;</li>
                    <li>
                      any liability that cannot be excluded or restricted as a result of section 31 and/or section 57 of
                      the Consumer Rights Act 2015;
                    </li>
                    <li>
                      any other liability for which it would be unlawful for us to attempt to limit or exclude our
                      liability.
                    </li>
                  </ol>
                </li>
              </ol>
            </li>
            <li>
              ALTERNATIVE DISPUTE RESOLUTION
              <ol type="a">
                <li>
                  Alternative dispute resolution is a process where an independent body considers the facts of a dispute
                  and seeks to resolve it, without you having to go to court. If you are not happy with how we have
                  handled any complaint or we fail to resolve a dispute between us in relation to a Membership Agreement
                  or any Services you have received, you may want to contact CEDR, a certified ADR provider with whom we
                  are willing to work. Further information about CEDR is available on their website at
                  https://www.cedr.com/.
                </li>
              </ol>
            </li>
            <li>
              MISCELLANEOUS
              <ol type="a">
                <li>
                  Choice of Law; Forum. These Terms shall be governed in all respects by the laws of the England and
                  Wales, without regard to conflict of law provisions. You agree that any claim or dispute you may have
                  against MealPal must be resolved by a court located in England or Wales, except as otherwise agreed by
                  the parties or as described in the Alternative Dispute Resolution Clause above. You agree to submit to
                  the exclusive jurisdiction of the courts of England and Wales for the purpose of litigating all such
                  claims or disputes.
                </li>
                <li>
                  Assignment. We may assign our rights and obligations under these Terms. The Terms will inure to the
                  benefit of our successors, assigns and licensees.
                </li>
                <li>
                  Severability. If any provision of these Terms shall be unlawful, void, or for any reason
                  unenforceable, then that provision will be deemed severable from these Terms and will not affect the
                  validity and enforceability of any remaining provisions.
                </li>
                <li>
                  Headings. The heading references are for convenience purposes only. They do not constitute a part of
                  these Terms, and will not be deemed to limit or affect any of the provisions.
                </li>
                <li>
                  Entire Agreement. Each party agrees that it shall have no remedies in respect of any statement,
                  representation, assurance or warranty (whether made innocently or negligently) that is not set out or
                  referred to in this Agreement. Each party agrees that it shall have no claim for innocent or negligent
                  misrepresentation based on any statement in this Agreement.
                </li>
                <li>
                  Waiver. No waiver of any of these Terms by MealPal is binding unless authorised in writing by an
                  executive officer of MealPal. In the event that MealPal waives a breach of any provision of these
                  Terms, such waiver will not be construed as a continuing waiver of other breaches of the same nature
                  or other provisions of these Terms and will in no manner affect the right of MealPal to enforce the
                  same at a later time.
                </li>
              </ol>
            </li>
          </ol>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default TermsAndConditions
